/**
 * Dependencies
 */
import {polaris} from "./config";
import {submitForm, websiteNav} from "./core";


/**
 * Public Page APIs
 */
export const pageAPIs = () => {
    // Acive toggle for menu plugin
    websiteNav('.menu-plugin', 'menu--active');
};

/**
 * Instatiate the Polaris Core
 */
const polaris = new Polaris.Core();
const slideshow = Polaris.Slideshow;


/**
 * Configure the Polaris class
 */
polaris.hideTimeout = 5000;


/**
 * Export objects
 */
export {polaris, slideshow};
/* ----------------------------------------------------------- */
/*          AIO CMS JS Library - © Aiomax Group 2024           */
/* ----------------------------------------------------------- */
/**
 * Import objects
 */
import {polaris, slideshow} from "./modules/config";
import {loadPage, websiteNav, scrollHandler} from "./modules/core";


/**
 * AIO object
 */
const AIO = {
    Polaris:       polaris,
    Slideshow:     slideshow,
    loadPage:      loadPage,
    websiteNav:    websiteNav,
    scrollHandler: scrollHandler
}


/**
 * Export default
 */
export default AIO;
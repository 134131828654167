/**
 * Dependencies
 */
import {polaris} from "./config";
import {submitForm, fetchData} from "./core";


/**
 * Settings App APIs
 */
export const settingsAPIs = () => {
    // General settings
    generalSettings();
    
    // Language settings
    langSettings();

    // CSS Settings
    cssSettings();

	// Edit app
    editApp();

	// Edit user
    editUser();
    
    // Analytics settings
    analyticsSettings();
    
    // Advanced settings
    advancedSettings();

    // Blog settings
    blogSettings();

    // Color settings
    colorSettings();

	// Manage links (quick access)
	manageLinks();
};


/**
 * General settings
 */
const generalSettings = () => {
    const form = document.querySelector('#general-settings');
    if (form) {
        form.onsubmit = () => {
            // Handle form submition
            submitForm(form)
            .then((result) => {
                // Check page refresh
                if (result.data.refresh) {
                    // Disable the submit
                    form.querySelector('.submit').setAttribute('disabled', true);
                    
                    // Update the dynamic link
                    const link = document.querySelector("#dynamic-link");
                    link.setAttribute('href', result.data.next);

                    // Trigger the click event
                    setTimeout(() => {
                        link.click();
                    }, 1000);
                }
            });

            // Prevent default behavior
            return false;
        };
    }
};


/**
 * Language settings
 */
const langSettings = () => {
    const form = document.querySelector('#language-settings');
    if (form) {
        form.onsubmit = () => {
            // Handle form submition
            submitForm(form);

            // Prevent default behavior
            return false;
        };
    }
}


/**
 * CSS settings
 */
const cssSettings = () => {
	const form = document.querySelector('#css-settings');
	if (form) {
		// Select Language
		let selectLanguage = form.querySelector("#select-language");

		// On change handler
		selectLanguage.onchange = function() {
			let language = this.value;
			let cssSource = form.querySelectorAll("#css-source > div");

			// CSS source
			cssSource.forEach((elem) => {
				if (elem.dataset.language == language) {
					elem.classList.remove('display-none');
				}
				else {
					elem.classList.add('display-none');
				}
			});
		};

		// Form submition
		form.onsubmit = () => {
            // Handle form submition
            submitForm(form);

            // Prevent default behavior
            return false;
		};
	}
};


/**
 * Edit app
 */
const editApp = () => {
    let manageApp = document.querySelector("#manage-app");
	if (manageApp) {
		let selectors = manageApp.querySelectorAll(".edit-app");
        let form = document.querySelector('#dynamic-form > form');

		// Edit app modal
        if (selectors.length > 0) {
			selectors.forEach((selector) => {
				selector.onclick = () => {
                    // Fetch requirements
					let id   = selector.dataset.id;
                    let name = selector.dataset.name;
                    let spa  = selector.dataset.spa;
                    let url  = selector.dataset.url;

                    // Update form
                    form.querySelector('#field-id').setAttribute('value', id);
                    form.querySelector('#field-url').setAttribute('value', url);

                    if (spa == '1') {
                        form.querySelector('#field-spa').setAttribute('checked', true);
                    }
                    else {
                        form.querySelector('#field-spa').removeAttribute('checked');
                    }

                    // Produce modal content
                    let title = `Edit ${name}`;
                    let body  = document.querySelector('#dynamic-form').innerHTML;

					// Create modal
                    polaris.modal(title, body, "", "xs", "zoomIn", "zoomOut", "", true, true, 500);

                    // Final form
                    let finalForm = document.querySelector('.modal').querySelector('form');

                    // Form submition
                    finalForm.onsubmit = () => {
                        // Handle form submition
                        submitForm(finalForm)
                        .then((result) => {
                            // On success
                            if (result.status) {
                                // Update app table
                                let tr = manageApp.querySelector(`tr[data-id='${id}']`);

                                // Update SPA
                                tr.querySelector('td.spa-text').innerHTML = result.data.spaText;
                                selector.dataset.spa = result.data.spa;
                            }
                        });
            
                        // Prevent default behavior
                        return false;
                    };

                    // Prevent default behavior
                    return false;
				}
			});
		}
	}
};


/**
 * Edit user
 */
const editUser = () => {
    let manageUser = document.querySelector("#manage-user");
	if (manageUser) {
		let selectors = manageUser.querySelectorAll(".edit-user");
        let form = document.querySelector('#dynamic-form > form');

		// Edit app modal
        if (selectors.length > 0) {
			selectors.forEach((selector) => {
				selector.onclick = () => {
                    // Fetch requirements
					let id       = selector.dataset.id;
                    let username = selector.dataset.username;
                    let type     = selector.dataset.type;
                    let status   = selector.dataset.status;
                    let access   = selector.dataset.access;
                    let apps     = selector.dataset.apps;

                    /**
                     * Update form
                     */
                    // Update ID
                    form.querySelector('#field-id').setAttribute('value', id);

                    // Update Type
                    form.querySelectorAll('#field-type option').forEach((elem) => {
                        if (elem.value == type) {
                            elem.setAttribute('selected', true);
                        }
                        else {
                            elem.removeAttribute('selected');
                        }
                    });

                    // Update Status
                    form.querySelectorAll('#field-status option').forEach((elem) => {
                        if (elem.value == status) {
                            elem.setAttribute('selected', true);
                        }
                        else {
                            elem.removeAttribute('selected');
                        }
                    });

                    // Access & Apps visiblility
                    if (type == 1) {
                        document.querySelector("#field-access-div").classList.remove("display-none");
                        document.querySelector("#field-apps-div").classList.remove("display-none");
                    }
                    else {
                        document.querySelector("#field-access-div").classList.add("display-none");
                        document.querySelector("#field-apps-div").classList.add("display-none");
                    }

                    // Update Access
                    form.querySelectorAll('#field-access-div .switch').forEach((elem) => {
                        // Update access input
                        if (elem.querySelector('input').value == access) {
                            elem.querySelector('input').setAttribute('checked', true);
                        }
                        else {
                            elem.querySelector('input').removeAttribute('checked');
                        }

                        // Update access switch
                        if (status == 1) {
                            elem.classList.remove('switch-disabled');
                            elem.querySelector('input').removeAttribute('disabled');
                        }
                        else {
                            elem.classList.add('switch-disabled');
                            elem.querySelector('input').setAttribute('disabled', true);
                        }
                    });

                    // Update Apps
                    form.querySelectorAll('#field-apps-div .switch').forEach((elem) => {
                        // Update apps input
                        if (apps.includes(elem.querySelector('input').value) || ['page', 'user', 'profile'].includes(elem.querySelector('input').value)) {
                            elem.querySelector('input').setAttribute('checked', true);
                        }
                        else {
                            elem.querySelector('input').removeAttribute('checked');
                        }

                        // Update apps switch
                        if (status == 1 && !['page', 'user', 'profile'].includes(elem.querySelector('input').value)) {
                            elem.classList.remove('switch-disabled');
                            elem.querySelector('input').removeAttribute('disabled');
                        }
                        else {
                            elem.classList.add('switch-disabled');
                            elem.querySelector('input').setAttribute('disabled', true);
                        }
                    });

                    // Produce modal content
                    let title = `Edit '${username}'`;
                    let body  = document.querySelector('#dynamic-form').innerHTML;

					// Create modal
                    polaris.modal(title, body, "", "w-600px", "zoomIn", "zoomOut", "", true, true, 500);

                    // Final form
                    let finalForm = document.querySelector('.modal').querySelector('form');

                    // User type change
                    finalForm.querySelector('#field-type').onchange = function() {
                        if (this.value == '1') {
                            finalForm.querySelector("#field-access-div").classList.remove("display-none");
                            finalForm.querySelector("#field-apps-div").classList.remove("display-none");
                        }
                        else {
                            finalForm.querySelector("#field-access-div").classList.add("display-none");
                            finalForm.querySelector("#field-apps-div").classList.add("display-none");
                        }
                    };

                    // User status change
                    finalForm.querySelector('#field-status').onchange = function() {
                        if (this.value == '1') {
                            // Enable Apps
                            finalForm.querySelectorAll('#field-apps-div .switch').forEach((elem) => {
                                if (!['page', 'user', 'profile'].includes(elem.querySelector('input').value)) {
                                    elem.classList.remove('switch-disabled');
                                    elem.querySelector('input').removeAttribute('disabled');
                                }
                            });

                            // Enable Access
                            finalForm.querySelectorAll('#field-access-div .switch').forEach((elem) => {
                                elem.classList.remove('switch-disabled');
                                elem.querySelector('input').removeAttribute('disabled');
                            });
                        }
                        else {
                            // Disable Apps
                            finalForm.querySelectorAll('#field-apps-div .switch').forEach((elem) => {
                                if (!['page', 'user', 'profile'].includes(elem.querySelector('input').value)) {
                                    elem.classList.add('switch-disabled');
                                    elem.querySelector('input').setAttribute('disabled', true);
                                }
                            });

                            // Disable Access
                            finalForm.querySelectorAll('#field-access-div .switch').forEach((elem) => {
                                elem.classList.add('switch-disabled');
                                elem.querySelector('input').setAttribute('disabled', true);
                            });
                        }
                    };

                    // Form submition
                    finalForm.onsubmit = () => {
                        // Handle form submition
                        submitForm(finalForm)
                        .then((result) => {
                            // On success
                            if (result.status) {
                                // Update app table
                                let tr = manageUser.querySelector(`tr[data-id='${id}']`);

                                // Update type
                                tr.querySelector('td.type-text').innerHTML = result.data.typeText;
                                selector.dataset.type = result.data.type;

                                // Update status
                                tr.querySelector('td.status-text').innerHTML = result.data.statusText;
                                selector.dataset.status = result.data.statusCode;

                                // Update access
                                if (result.data.access) {
                                    selector.dataset.access = result.data.access;
                                }

                                // Update apps
                                if (result.data.apps) {
                                    selector.dataset.apps = result.data.apps;
                                }
                            }
                        });
            
                        // Prevent default behavior
                        return false;
                    };

                    // Prevent default behavior
                    return false;
				}
			});
		}
	}
};


/**
 * Analytics settings
 */
const analyticsSettings = () => {
    const form = document.querySelector('#analytics-settings');
    if (form) {
        form.onsubmit = () => {
            // Handle form submition
            submitForm(form);

            // Prevent default behavior
            return false;
        };
    }
};


/**
 * Advanced settings
 */
const advancedSettings = () => {
    const form = document.querySelector('#advanced-settings');
    if (form) {
        form.onsubmit = () => {
            // Handle form submition
            submitForm(form);

            // Prevent default behavior
            return false;
        };
    }
};


/**
 * Blog settings
 */
export const blogSettings = () => {
    const form = document.querySelector('#blog-settings');
    if (form) {
        form.onsubmit = () => {
            // Handle form submition
            submitForm(form);

            // Prevent default behavior
            return false;
        };
    }
};


/**
 * Color settings
 */
export const colorSettings = () => {
    const form = document.querySelector('#color-settings');
    const doc  = document.querySelector('body');
    if (form) {
        // Color tune
        const tune = document.querySelector('#gray-tune');
        tune.onchange = function() {
            const current_tune  = doc.dataset.tune;

            doc.classList.remove(`doc-${current_tune}`);
            doc.classList.add(`doc-${this.value}`);

            doc.dataset.tune = this.value;
        };

        // App color
        const colors = document.querySelectorAll('.pallete--items');
        colors.forEach(color => {
            color.onclick = function() {
                const current_color = doc.dataset.color;
                const current_item  = document.querySelector('.pallete--active');
    
                if (color !== current_item) {
                    doc.classList.remove(`doc-${current_color}`);
                    doc.classList.add(`doc-${this.dataset.color}`);
                    doc.dataset.color = this.dataset.color;

                    form.querySelector('#field-color').value = this.dataset.color;
        
                    current_item.classList.remove('pallete--active');
                    color.classList.add('pallete--active');
                }
            };
        });

        // Form submit
        form.onsubmit = () => {
            // Handle form submition
            submitForm(form);

            // Prevent default behavior
            return false;
        };
    }
};


/**
* Manage links (quick access)
*/
const manageLinks = () => {
   const parent = document.querySelector("#manage-links");

   // Check container
   if (parent) {
       const output	     = parent.querySelector("#links-output");
       let   dynamicForm = document.querySelector('#dynamic-form');
       const action      = dynamicForm.dataset.action;

       // Add link
       if (parent.querySelector("#create-link-form")) {
           const form = parent.querySelector("#create-link-form");
           form.onsubmit = () => {
               // Handle form submition
               submitForm(form)
               .then((result) => {
                   // On success
                   if (result.status) {
                       // Update the output
                       output.innerHTML = result.data.output;

                       // Reset the form
                       form.reset();

                       // Initialize polaris
                       polaris.init();

                       // Method recursion
                       manageLinks();
                   }
               });
   
               // Prevent default behavior
               return false;
           };
       }

       // Add sublink
       if (parent.querySelectorAll(".add-sublink").length > 0) {
           const selectors = parent.querySelectorAll(".add-sublink");

           selectors.forEach((selector) => {
               selector.onclick = () => {
                   const id 	= selector.getAttribute("data-id");
                   dynamicForm = document.querySelector('#dynamic-form');

                   // Produce modal content
                   const title = 'Add Sublink';
                   const body  = `
                   <form action="${action}" method="post">
                       ${dynamicForm.innerHTML}
                   </form>
                   `;

                   // Create modal
                   polaris.modal(title, body, "", "sm", "zoomIn", "zoomOut", "", true, true, 500);

                   // Final form
                   const form = document.querySelector('.modal').querySelector('form');

                   // Update form
                   form.querySelector('input[name=form-type]').value = 'create-sublink';
                   form.querySelector('input[name=id]').value = id;
                   form.querySelector('.form-body').innerHTML = `
                       <div class="row">
                           <div class="col col-12 t-col-6">
                               <div class="row gt-sm">
                                   <div class="col w-100 lt-w-150px">
                                       <label for="field-title">*Title</label>
                                   </div>
                                   <div class="col w-rest">
                                       <input type="text" name="title" id="field-title" class="field field-sm field-focus" required>
                                   </div>
                               </div>
                           </div>
                           <div class="col w-100 t-col-6">
                               <div class="row gt-sm">
                                   <div class="col w-100 lt-w-150px">
                                       <label for="field-link">*Link URL:</label>
                                   </div>
                                   <div class="col w-rest">
                                       <input type="text" name="link" class="field field-sm field-focus" id="field-link" value="#" required>
                                   </div>
                               </div>
                           </div>
                           <div class="col col-12 t-col-6">
                               <div class="row gt-sm">
                                   <div class="col w-100 lt-w-150px">
                                       <label for="field-target">Link Target</label>
                                   </div>
                                   <div class="col w-rest">
                                       <select name="target" class="field field-sm field-focus" id="field-target">
                                           <option value="_self" selected="">Self</option>
                                           <option value="_blank">Blank</option>
                                       </select>
                                   </div>
                               </div>
                           </div>
                           <div class="col col-12 t-col-6 mb-md">
                               <div class="row gt-sm">
                                   <div class="col w-100 lt-w-150px">
                                       <label for="field-spa">SPA Link:</label>
                                   </div>
                                   <div class="col w-rest">
                                       <div class="switch switch-slim switch-sm">
                                           <input type="checkbox" name="spa" id="field-spa">
                                           <div class="switch--slider"></div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <button type="submit" class="btn btn-sm btn-app btn-border btn-focus submit mb-min">Submit</button>
                   `;

                   // Form submition
                   form.onsubmit = () => {
                       // Handle form submition
                       submitForm(form)
                       .then(result => {
                           // On success
                           if (result.status) {
                               // Update the output
                               output.innerHTML = result.data.output;
       
                               // Reset the form
                               form.reset();

                               // Initialize polaris
                               polaris.init();

                               // Method recursion
                               manageLinks();
                           }
                       });

                       // Prevent default behavior
                       return false;
                   };
               }
           });
       }

       // Edit link | sublink
       if (parent.querySelectorAll(".edit-link").length > 0) {
           const selectors = parent.querySelectorAll(".edit-link");

           selectors.forEach((selector) => {
               selector.onclick = () => {
                   const id 	= selector.getAttribute("data-id");
                   const sub 	= selector.getAttribute("data-sub");
                   dynamicForm = document.querySelector('#dynamic-form');

                   // Produce modal content
                   const title = Number(sub) ? 'Edit Sublink' : 'Edit Link';
                   const body  = `
                   <form action="${action}" method="put">
                       ${dynamicForm.innerHTML}
                   </form>
                   `;

                   // Create modal
                   polaris.modal(title, body, "", "sm", "zoomIn", "zoomOut", "", true, true, 500);

                   // Final form
                   const form = document.querySelector('.modal').querySelector('form');

                   // Update form
                   form.querySelector('input[name=form-type]').value = 'edit-link';
                   form.querySelector('input[name=id]').value = id;
                   form.querySelector('.form-body').innerHTML = 'Loading...';

                   // Fetch the form body
                   fetchData(null, {linkSource: id}, action, 'put', null)
                   .then(result => {
                       if (result.status) {
                           // Update the form body
                           form.querySelector('.form-body').innerHTML = result.data.source;

                           // Form submition
                           form.onsubmit = () => {
                               // Handle form submition
                               submitForm(form)
                               .then(result => {
                                   // On success
                                   if (result.status) {
                                       // Update the output
                                       output.innerHTML = result.data.output;
               
                                       // Initialize polaris
                                       polaris.init();
       
                                       // Method recursion
                                       manageLinks();
                                   }
                               });
       
                               // Prevent default behavior
                               return false;
                           };
                       }
                   });
               }
           });
       }

       // Delete link
       if (parent.querySelectorAll('.delete-link').length > 0) {
           const selectors = parent.querySelectorAll('.delete-link');

           selectors.forEach((selector) => {
               // Delete link
               selector.onclick = function() {
                   const id 	= this.dataset.id;
                   const popup = parent.querySelector(`#delete-popup-${id}`);
       
                   // Start the delete process
                   fetchData(selector, {delete_link: id}, action, 'delete')
                   .then((result) => {
                       // On success
                       if (result.status) {
                           // Close popup
                           popup.classList.remove("popup-open");

                           // Update the output
                           output.innerHTML = result.data.output;

                           // Initialize polaris
                           polaris.init();

                           // Method recursion
                           manageLinks();
                       }
                   });
               };
           });
       }

       // Sorting link
       const containers = document.querySelectorAll('.draggable-list');
       if (containers.length) {
           containers.forEach(container => {
               container.onmousedown = () => {
                   polaris.draggable(container).then(() => {
                       // Fetch required data
                       const form 	 = document.querySelector('#dynamic-form');
                       const action = form.dataset.action;
                       const method = form.dataset.method;
   
                       const draggables = Array.from(container.children);
                       let   data 		 = {};
   
                       draggables.forEach(draggable => {
                           data[draggable.dataset.id] = Number(draggable.dataset.order);
                       });
   
                       // Perform the sorting process
                       fetchData(null, {sortLink: data}, action, method, null);
                   });
               };
           });
       }

   }	
};

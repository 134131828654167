/**
 * Dependencies
 */
import {polaris} from "./config";
import {submitForm} from "./core";


/**
 * Analytics App APIs
 */
export const analyticsAPIs = () => {
    
};

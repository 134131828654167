/**
 * Dependencies
 */
import {polaris} from "./config";
import {submitForm} from "./core";


/**
 * User App APIs
 */
export const userAPIs = () => {
    // Register form
    registerForm();
    
    // Login form
    loginForm();
};


/**
 * Register form
 */
const registerForm = () => {
    const form = document.querySelector('#register-form');
    if (form) {
        form.onsubmit = () => {
            // Handle form submition
            submitForm(form, true).then((result) => {
                // On success
                if (result.status) {
                    // Redirect to `url` after 1 second
                    // setTimeout(function() {
                    //     polaris.redirect(result.data.url);
                    // }, 1000);

                    // Update the dynamic link
                    let link = document.querySelector("#dynamic-link");
                    link.setAttribute('href', result.data.url);

                    // Trigger the click event
                    link.click();
                }
            });

            // Prevent default behavior
            return false;
        };
    }
};


/**
 * Login form
 */
const loginForm = () => {
    const form = document.querySelector('#login-form');
    if (form) {
        form.onsubmit = () => {
            // Handle form submition
            submitForm(form, true)
            .then((result) => {
                // On success
                if (result.status) {
                    // Redirect to `url` after 1 second
                    // setTimeout(function() {
                    //     polaris.redirect(result.data.url);
                    // }, 1000);

                    // Update the dynamic link
                    let link = document.querySelector("#dynamic-link");
                    link.setAttribute('href', result.data.url);

                    // Trigger the click event
                    link.click();
                }
            });

            // Prevent default behavior
            return false;
        };
    }
};
